<template>
  <PageContainer>
    <BaseSearchForm
      @search="onSearch"
      @reset="onReset"
      :loading="loading">
      <el-form-item label="企业:">
        <el-select v-model="companySelected" filterable placeholder="全部企业" clearable>
          <el-option v-for="item in companyList" :key="item.compId" :value="item.compId" :label="item.compName"/>
        </el-select>
      </el-form-item>
      <el-form-item label="姓名:">
        <el-input v-model.trim="searchForm.persName" placeholder="请输入" clearable/>
      </el-form-item>
      <el-form-item label="身份证号:">
        <el-input v-model.trim="searchForm.cardID" placeholder="驾驶证号" clearable />
      </el-form-item>
    </BaseSearchForm>

    <BaseTable
      id="professionalManage"
      @page-change="handlePageChange"
      :tablePage="tablePage"
      :loading="loading"
      :data="tableData">
      <template #buttons>
        <el-button v-auth="'信息管理_专业人员管理_新增'" size="small" type="primary" icon="el-icon-plus" @click="addVisible=true">新增</el-button>
      </template>
      <vxe-column field="seq" type="seq" title="序号" width="80" align="center"></vxe-column>
      <vxe-column field="lastCompName" title="现工作单位" min-width="120"></vxe-column>
      <vxe-column field="cardID" title="身份证" min-width="150"></vxe-column>
      <vxe-column field="persName" title="姓名" min-width="120"></vxe-column>
      <vxe-column field="ccieTypeName" title="证件类型" min-width="120"></vxe-column>
      <vxe-column field="ccieCode" title="上岗证号" min-width="120"></vxe-column>
      <vxe-column field="ccieName" title="资格证" min-width="80"></vxe-column>
      <vxe-column field="dutyStatName" title="任职状态" min-width="120"></vxe-column>
      <vxe-column field="persTypeName" title="职位" min-width="120"></vxe-column>
      <vxe-column field="phone" title="手机号" min-width="120"></vxe-column>
      <vxe-column field="persTypeName" title="职位/工种" min-width="100"></vxe-column>
      <vxe-column field="operations" title="操作" :width="385" fixed="right">
        <template #default={row}>
          <el-button type="primary" @click="handlerQrCode(row)" title="二维码">二维码</el-button>
          <el-button v-auth="'信息管理_专业人员管理_离职'" type="primary" @click="handlerLeave(row)" title="离职">离职</el-button>
          <el-button v-auth="'信息管理_专业人员管理_奖惩'" type="primary" @click="handlerBonus(row)" title="奖惩">奖惩</el-button>
          <el-button v-auth="'信息管理_专业人员管理_转正'" type="primary" @click="handlerFull(row)" title="离职">转正</el-button>
          <el-button v-auth="'信息管理_专业人员管理_修改'" type="primary" @click="handlerEdit(row)" title="修改">修改</el-button>
          <el-button v-auth="'信息管理_专业人员管理_明细'" type="primary" @click="handlerDetail(row)" title="明细">明细</el-button>
          <el-button v-auth="'信息管理_专业人员管理_删除'" type="danger" @click="handlerDelete(row)" title="删除">删除</el-button>
        </template>
      </vxe-column>
    </BaseTable>

    <!-- 新增人员 -->
    <add :type="searchForm.persType" :visible.sync="addVisible" :edit-data.sync="editData" @refresh="initList" />
    <!-- 离职 -->
    <leave-comp :visible.sync="leaveVisible" :edit-data.sync="editData" @refresh="initList" />
    <!-- 详情 -->
    <detail :type="searchForm.persType" :visible.sync="detailVisible" :edit-data.sync="editData" />
    <!-- 奖惩 -->
    <bonus-penalty :visible.sync="bonusVisible" :edit-data.sync="editData" @refresh="initList" />
    <!-- 转正 -->
    <become-full :visible.sync="fullVisible" :edit-data.sync="editData" @refresh="initList" />
    <qr-code :visible.sync="codeVisible" :content="qrCodeContent" />
  </PageContainer>
</template>

<script>
import { getPersList, delPers } from '@/apis/infoManage/common'
import { gianComplist } from '@/apis/common'
import { persTypes, ccieTypes, dutyTypes } from '@/views/infoManage/personManage/constants'
import Add from '@/views/infoManage/personManage/components/Add'
import LeaveComp from '@/views/infoManage/personManage/components/LeaveComp'
import Detail from '@/views/infoManage/personManage/components/Detail'
import BonusPenalty from '@/views/infoManage/personManage/components/BonusPenalty'
import BecomeFull from '@/views/infoManage/personManage/components/BecomeFull'
import QrCode from '@/components/QrCode'

export default {
  name: 'organization',
  data () {
    return {
      dutyTypes,
      persTypes,
      ccieTypes,
      searchForm: {
        persName: '',
        cardID: '',
        lastCompId: '',
        persType: 1 // 专业技术人员
      },
      // 列表相关
      loading: false,
      tableData: [],
      tablePage: {
        pageNum: 1,
        pageSize: 20,
        totalSize: 0
      },
      // 新增|编辑相关
      editData: null,
      addVisible: false,
      bonusVisible: false, // 奖惩
      leaveVisible: false, // 离职
      detailVisible: false, // 明细
      fullVisible: false, // 转正
      codeVisible: false, // 二维码
      qrCodeContent: '', // 二维码内容
      companyList: []
    }
  },
  computed: {
    // 选中的企业
    companySelected: {
      set (newVal) {
        const companyList = this.companyList
        const searchForm = this.searchForm
        const { compId } = companyList.find(item => newVal == item.compId) || { compId: '' }
        searchForm.lastCompId = compId
      },
      get () { return this.searchForm.lastCompId }
    }

  },
  created () {
    this.initList()
    this.initCompany()
  },
  methods: {
    onReset () {
      const source = this.$options.data(this).searchForm
      this.searchForm = Object.assign({}, this.searchForm, source)
      this.onSearch()
    },
    async initCompany () {
      const res = await gianComplist({ pageNum: 1, pageSize: 100000 })
      if (res.code == 200) {
        this.companyList = res.data.rows
      } else {
        this.$message.error(res.msg)
      }
    },
    onSearch () {
      this.tablePage.pageNum = 1
      this.initList()
    },

    initList () {
      this.loading = true
      const { totalSize, ...pageParams } = this.tablePage
      const dutyTypes = this.dutyTypes
      const params = Object.assign({}, this.searchForm, pageParams)
      getPersList(params).then((res) => {
        this.loading = false
        if (res.code == 200) {
          this.tableData = res.data.rows.map((item) => {
            const target = dutyTypes.find(({ id }) => id == item.dutyStat) || { name: '-' }
            item.dutyStatName = target.name
            const postName = item.seniPosit || item.ordiPosit || '未知'
            item.persTypeName = postName + '/' + (persTypes.find(({ id }) => id == item.persType) || { name: '' }).name
            // 证书类型
            item.ccieTypeName = (ccieTypes.find(({ id }) => id == item.ccieType) || { name: '' }).name
            return item
          })
          this.tablePage.totalSize = res.data.totalSize
        } else {
          this.$message.error(res.msg)
        }
      })
    },

    handlePageChange ({ currentPage, pageSize }) {
      this.tablePage.pageNum = currentPage
      this.tablePage.pageSize = pageSize
      this.initList()
    },

    handlerEdit (row) {
      this.editData = JSON.parse(JSON.stringify(row))
      this.addVisible = true
    },

    // 奖惩
    handlerBonus (row) {
      this.editData = JSON.parse(JSON.stringify(row))
      this.bonusVisible = true
    },

    // 离职
    handlerLeave (row) {
      this.editData = JSON.parse(JSON.stringify(row))
      this.leaveVisible = true
    },

    // 明细
    handlerDetail (row) {
      this.editData = JSON.parse(JSON.stringify(row))
      this.detailVisible = true
    },

    // 奖惩
    handlerFull (row) {
      this.editData = JSON.parse(JSON.stringify(row))
      this.fullVisible = true
    },

    // 删除
    handlerDelete (row) {
      this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        const params = { cardID: row.cardID, persId: row.persId }
        delPers(params).then((res) => {
          if (res.code == 200) {
            this.initList()
            this.$message.success('刪除成功！')
          } else {
            this.$message.error(res.msg)
          }
        })
      })
    },
    handlerQrCode (row) {
      this.codeVisible = true
      this.qrCodeContent = `姓名: ${row.persName};身份证号: ${row.cardID};`
    }
  },
  components: {
    Add,
    BonusPenalty,
    LeaveComp,
    Detail,
    BecomeFull,
    QrCode
  }
}
</script>

<style lang="scss" scoped>
  .system-icon-box {
    .system-icon-item {
      object-fit: contain;
      vertical-align: middle;
    }
  }
</style>
